<template>
  <div class="request-history">
    <PageHeader
      title="Conversations"
    />

    <PageContent>
      <section v-if="chats.length">
        <ul role="list" class="divide-y divide-gray-200">
          <li v-for="chat in chats" :key="chat.uuid" class="hover:bg-gray-50">
            <router-link :to="`/app/chats/${chat.uuid}`"
              class="block "
            >
              <div class="flex items-center px-4 py-4 sm:px-6">
                <div class="min-w-0 flex-1 flex items-center justify-between">
                  <div class="flex-shrink-0">
                    <avatar :user="chat.initiator" />
                  </div>
                  <div class="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
                    <div>
                      <p class="text-sm font-medium text-primary-600 truncate">
                        <span v-if="chat.initiator.firstname || chat.initiator.lastname">
                          <span v-if="chat.initiator.firstname">
                            {{ chat.initiator.firstname }}
                          </span>
                          <span v-if="chat.initiator.lastname">
                            {{ chat.initiator.lastname }}
                          </span>
                        </span>
                      </p>
                      <p class="flex items-center text-sm text-gray-500">
                        <span class="truncate">{{ chat.initiator.email }}</span>
                      </p>
                    </div>
                    <div class="hidden md:block">
                      <div>
                        <p class="text-sm text-gray-900">
                          Dernier message le {{ formatDate(chat.messages[chat.messages.length - 1].created_at, 'dd LLLL Y HH:mm') }} :
                        </p>
                        <p class="flex items-center text-sm text-gray-500">
                          {{ chat.messages[chat.messages.length - 1].content }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div class="request-icon has-text-success"
                    v-show="chat.hasNewMessage"
                  ><b-icon icon="fas fa-circle"></b-icon></div>
                  <b-icon icon="fas fa-chevron-right"
                    class="h-5 w-5 text-gray-400"/>
                </div>
              </div>
            </router-link>
          </li>
        </ul>
      </section>
    </PageContent>
  </div>
</template>

<script>
import { parseISO, format } from 'date-fns'
import fr from 'date-fns/locale/fr'
import { mapGetters } from 'vuex'
import { socket } from '@/services/socket.service'
import PageHeader from '@/components/PageHeader.vue'
import PageContent from '@/components/PageContent.vue'
import Avatar from '../../components/Avatar.vue'

export default {
  components: {
    PageHeader,
    PageContent,
    Avatar
  },
  data () {
    return {
      requests: [],
      requestViewed: {},
      loading: false
    }
  },
  computed: {
    ...mapGetters([
      'chats'
    ])
  },
  mounted () {
    this.getChats()
    this.setUpSocketListeners()
  },
  methods: {
    getChats () {
      this.$store.dispatch('FETCH_MY_CHATS')
    },
    openChat (chat) {
      if (this.$route.params.uuid !== chat.uuid) {
        chat.hasNewMessage = false
        this.$router.push({
          path: `/app/chats/${chat.uuid}`
        })
      }
    },
    formatDate (date, dateFormat) {
      return format(parseISO(date), dateFormat, {
        locale: fr
      })
    },
    setUpSocketListeners () {
      socket.on('chat:new', chat => {
        if (chat.assignee.uuid === this.user.uuid) {
          this.$browserPush.newRequest(chat)
          const chats = [...this.chats, chat]
          this.$store.commit('SET_MY_CHATS', chats)
        }
      })

      socket.on('chat:new-message', ({ request, message }) => {
        this.chats.map((chat, index) => {
          if (chat.uuid === request.uuid) {
            chat.hasNewMessage = true
            this.$set(this.chats, index, chat)
          }
        })
      })
    }
  }
}
</script>

<style scoped lang="scss">
.chat-history {
  width: 100%;
  display: flex;
  align-items: flex-start;
  height: 100%;
}
.chats-list {
  border-right: 1px solid #e5e5e5;
  width: 250px;
  height: 100%;

  .chats-list-item {
    background-color: white;
    border-bottom: 1px solid #e5e5e5;
    line-height: 20px;
    position: relative;

    &:last-child {
      border-bottom: 0;
    }

    .messages-count {
      position: absolute;
      top: 10px;
      right: 10px;

      .tag {
        background-color: #CBD8EA;
        color: #445877;
        border-radius: 50px;
      }
    }

    a {
      display: block;
      padding: 10px 35px 10px 10px;
      border-right: 4px solid transparent;

      &:hover {
        border-right: 4px solid #3b82f6;
      }
    }
  }
}
.chat-messages-empty {
  height: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
}
.chat-messages {
  display: flex;
  flex-direction: column;
}
.chat-message {
  padding: 20px;
  max-width: 70%;

  &.chat-author-is-replier {
    align-self: flex-end;
    .chat-message-message {
      margin-left: auto;
    }
  }

  .chat-message-meta {

  }
  .chat-author {
    display: flex;
  }
  .chat-author-avatar {
    height: 30px;
    width: 30px;
    font-size: 12px;
    border-radius: 50px;
    background-color: #3b82f6;
    color: #fff;
    text-align: center;
    line-height: 30px;
  }
  .chat-author-name {
    line-height: 30px;
    margin-left: 6px;
  }
  .chat-message-message {
    background-color: #F2F2F2;
    border-radius: 10px;
    padding: 10px 15px;
    margin-top: 6px;
    width: fit-content;
  }
  .chat-message-datetime {
    text-align: right;
    font-size: 11px;
  }
}
.chats-content {
  width: 100%;
  height: 100%;
}
.chat-header {
  padding: 20px;
  border-bottom: 1px solid #e5e5e5;
}
</style>
